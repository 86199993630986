




















import { Component, Mixins, Vue, Watch } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import SeminarStudentHistoryHeader from '@/components/organisms/SeminarStudentHistoryHeader.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import StudentHistoryTotal from '@/components/organisms/StudentHistoryTotal.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import { FormatResultSummaries } from '@/models/api/resultSummaries'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'

@Component({
  components: {
    SidebarSwitcher,
    SeminarStudentHistoryHeader,
    ColoredBox,
    StudentHistoryTotal,
    SelectBase,
  },
})
export default class LearningSituation extends Mixins(ClassModeChangeAble) {
  private userId = Vue.prototype.$cookies.get('authGdls').currentApiUserId

  private totalStatusDatas = {}

  private selectSeminarOptions: { text: string; value: string }[] = []

  private selectedSeminar = ''

  private hasSeasonDate = true

  @Watch('selectedSeminar')
  onSelectedTestChange(newTestDate: string) {
    if (newTestDate) {
      const [start, end, _] = newTestDate.split(',')
      if (start && end) {
        this.hasSeasonDate = true
        this.getTotalStatusDatas({ start: start, end: end })
      } else {
        this.hasSeasonDate = false
      }
    }
  }

  private async getTotalStatusDatas(params: { start: string; end: string }) {
    Vue.prototype.$loading.start()
    await this.getResultSummaries({ start: params.start, end: params.end })
    Vue.prototype.$loading.complete()
  }

  private async created() {
    Vue.prototype.$loading.start()
    await this.checkClassModeAndSideMenuMode()
    await this.getSeasonSettingsSeasons()
    Vue.prototype.$loading.complete()
  }

  private async getSeasonSettingsSeasons() {
    await Vue.prototype.$http.httpWithToken
      .get('/seasonSettings/seasons', { params: { userId: this.userId } })
      .then((res: any) => {
        if (res.data.length === 0) {
          this.hasSeasonDate = false
          return
        }
        this.selectSeminarOptions = res.data.map(
          (season: { gradeName: string; seasonName: string; from: string; to: string }, index: number) => {
            return { text: `${season.gradeName} ${season.seasonName}`, value: `${season.from},${season.to},${index}` }
          }
        )
        const selectedIndex = res.data.findIndex((season: { isSelected: boolean }) => {
          return season.isSelected === true
        })
        const selected = res.data[selectedIndex]
        this.selectedSeminar = `${selected.from},${selected.to},${selectedIndex}`
      })
  }

  private async getResultSummaries(params: { start: string; end: string }) {
    await Vue.prototype.$http.httpWithToken
      .get(`/history/resultSummaries/${this.userId}`, {
        params: { classModeCode: 'KS', startAt: params.start, endAt: params.end },
      })
      .then((res: any) => {
        this.totalStatusDatas = FormatResultSummaries(res.data)
      })
  }
}
